/* Programs */
.program-title{
  text-align: center;
}
.programs-header{
  margin-top: 8%;
  text-align: center;
  position: relative;
}
.programs{
  display: flex;
  flex: 1 1 0px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  height: auto;
}
.card-group{
  width: 80vw;
  flex-flow: row wrap;
}
.card-title{
  padding-left: 2rem;
  font-size: 1.2rem;
  margin-top: 2%;
}
.card-text{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  font-size: .9rem;
  color: var(--text-color-black);
}
.programs-card-container{
  display: flex;
  border-radius: 30px;
  border-style: solid;
  border-color: var(--scheme-gray);
  border-width: 2px;
  width: 75%;
  height: auto;
}
.programs-card{
  width: 33.33333%;
  height: auto;
  padding: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .5rem;

  border-left: 1px;
  border-right: 0px;
  border-top: 0;
  border-bottom: 0;
  border-style: solid;
  border-color: var(--scheme-gray);
}
.programs-card > img{
  width: 100%;
  height: 50%;
  border-radius: 20px;
}
.programs-card > h4{
  margin-top: 1rem;
  margin-left: .5rem;
}
.programs-card:nth-child(1){
  border-left: 0px;
}
@media screen and (max-width: 1100px) {
  .programs-card{
    font-size: .8rem;
  }
}