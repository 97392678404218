:root{
  /* FEFFFF */
  /* F7F9FB */
  --navbar-color: #F7F9FB;
  --quick-links-text-color: #135344;
  --navbar-text: #1e7e4e;
  --scheme-black: #040F0F;
  --scheme-white: #FFFFFF;
  --scheme-gray: #BEBFC4;
  --scheme-green: #183C28;
  --scheme-yellow: #EEC643;
  --nta-green: #145434; 
  --nta-green2: #185434;
  --text-color-black: #555555;

  --image-slide-time: .8s;
  --bottom-slide-percentage: 15vh;

  color: var(--scheme-gray);
  background-color: #FEFFFF;
  font-family: 'Roboto', sans-serif;
  border-style: border-box;
}
h1,h2,h3,h4{
  /* font-family: 'Raleway', monospace; */
  font-family: 'Nunito', monospace;
}
/* Change chrome autofill color to black */
.bottom-nav-form > input:-webkit-autofill,
.bottom-nav-form > input:-webkit-autofill:hover, 
.bottom-nav-form > input:-webkit-autofill:focus, 
.bottom-nav-form > input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px black inset !important;
}
/*Change text in autofill textbox*/
.bottom-nav-form > input:-webkit-autofill{
  -webkit-text-fill-color: white !important;
}
.App{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.desktop-tab{
  margin-left: 1.5rem;
  opacity: 1;
  /* color: var(--quick-links-text-color); */
  /* color: rgb(9, 97, 9); */
  color: rgb(9, 87, 9);

  font-size: 1.2rem;
}
#everything-except-navbar{
  position: absolute;
  top: 0;
}
/* Navbar */
.bottom-nav{
  height: 10vh;
  position: fixed;
  bottom: 0px;
  background-color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  line-height: 10vh;
  z-index: 10;
}
.bottom-nav-arrow{
  position: relative;
  width: 2vh;
  height: 2vh;
  border-top: 2px;
  border-right: 0px;
  border-left: 2px;
  border-bottom: 0px;
  border-color: var(--nta-green);
  border-style: solid;
  z-index: 101;
  margin-left: 2%;

  transform: rotate(45deg);
}
.slide-down-animation{
  animation: bottom-nav-slide-down 1s;
}
.turn-bottom-arrow-down{
  animation: turn-bottom-arrow-down 1s;
}
@keyframes turn-bottom-arrow-down {
  0%{
    transform: rotate(225deg);
  }
  100%{
    transform: rotate(45deg);
  }
}
.open-bottom-nav{
  height: 80vh;
  animation: bottom-nav-slide 1s;
}
.turn-bottom-nav-arrow{
  animation: bottom-nav-arrow-turn 1s;
  transform: rotate(225deg);
}
.bottom-text-container{
  top: 0;
  width: 100%;
  position: absolute;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 2px;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px;
  border-style: solid;
  border-color: var(--quick-links-text-color);

}
.bottom-text-container:hover{
  cursor: pointer;
}
.bottom-nav-text{
  margin: 0;
  width: 20rem;
}
.open-bottom-nav-arrow{
  display: none;
}
@keyframes bottom-nav-arrow-turn {
  0%{
    transform: rotate(45deg);
  }
  100%{
    transform: rotate(225deg);
  }
}
@keyframes bottom-nav-slide {
  0%{
    height: 10vh;
  }
  100%{
    height: 80vh;
  }
}
@keyframes bottom-nav-slide-down {
  0%{
    height: 80vh;
  }
  100%{
    height: 10vh;
  }
}
@keyframes bottom-form-slide {
  0%{
    bottom: -56%;
  }
  100%{
    bottom: var(--bottom-slide-percentage);
  }
}
@keyframes bottom-form-slide-down {
  0%{
    bottom: var(--bottom-slide-percentage);
  }
  100%{
    bottom: -56%;
  }
}
.bottom-nav-form{
  position: fixed;
  z-index: 100;
  color: white;
  /* background-color: var(--nta-green); */
  width: 80%;
  margin-left: 10%;
  padding: 2%;
  bottom: var(--bottom-slide-percentage);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: bottom-form-slide 1s;
}
.closed-form{
  animation: bottom-form-slide-down 1s;
  animation-fill-mode: forwards;
}
.first-closed-form{
  display: none;
  animation: none;
  animation-fill-mode: none;
}
.no-bottom-border{
  animation: remove-bottom-border 1s;
  animation-delay: .99s;
  animation-fill-mode: forwards;  
}
@keyframes remove-bottom-border {
  0%{
    border-bottom: 2px;
  }
  100%{
    border-bottom: 0px;
  }
}
.no-bottom-nav-border{
  border-bottom: 0;
}
.bottom-nav-form > label{
  width: 100%;
  padding-left: 20%;
  margin-top: 1%;
}
.asterisk{
  color: orange;
}
.bottom-form-submit-button{
  padding: 1%;
  margin-top: 1%;
  width: 10%;
  margin-left: 50%;
}
.bottom-nav-form > input{
  width: 60%;
  color: white;
  border-style: none;
  padding: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  /* text-align: center; */
  border-bottom: 3px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-style: solid;
  border-color: white;
  background-color: black;
}
.bottom-nav-form > input:focus{
  /* border-color: rgba(229, 23, 23, 0.8);
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 4px rgba(229, 23, 23, 0.6); */
  outline: 0 none;
  border-color: var(--nta-green);
}
.show-hover:hover{
  cursor: pointer;
}
.mynav{
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  height: 10vh;
  width: 100%;
  display: flex;
  z-index: 10;

  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px;
  border-color: var(--scheme-black);
  border-style: solid;

  align-items: center;
}
.nav-tab-container{
  display: flex;
  position: absolute;
  right: 5%;
}
.nav-tab{
  margin-left: 1.5rem;
  opacity: 1;
  /* color: var(--quick-links-text-color); */
  /* color: rgb(9, 97, 9); */
  /* color: rgb(9, 87, 9); */
  color: var(--navbar-text);

  font-size: 1.2rem;
}
.nav-tab:hover{
  cursor: pointer;
  opacity: .9;
  transition-duration: 500ms;
  color: green;
}
.icon{
  font-size: 1.2rem;
  margin-right: 20%;
  height: 80%;
  width: auto;
  position: relative;

  z-index: 0;
}




/* Mobile Nav */
.mobile-nav{
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
  background-color: #0d482a;
  z-index: 4;
  color: white;
  height: 100vh;

  animation-name: slide;
  animation-duration: 1s;
}
.mobile-nav-tab{
  text-align: center;
  margin-top: 10%;
}
.mobile-nav-tab:nth-child(1){
  margin-top: 20%;
}
.hamburger-nav{
  position: fixed;
  z-index: 10;
  right: 5%;
}
.hamburger-line{
  width: 7vw;
  border-width: 1vw;
  border-style: solid;
  border-radius: 25px;
  margin-top: 10%;
  background-color: white;
  border-color: white;
}

@keyframes slide {
  0%{
    width: 0;
  }
  100% { 
    width: 80%; 
  }
}




/* Loading Page */
.loading-page{
  background-color: whitesmoke;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-page-spinner {
  position: relative;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50px;
  animation: spinner 1.5s linear infinite;
}
.spinner-page-container{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--nta-green);
  color: white;
  width: 30vw;
  height: auto;
  padding-top: 5%;
  padding-bottom: 5%;
  z-index: 11000;
  border-radius: 10px;
}
.spinner-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 11000;

  left: 0;
}
.inner-spinner-page-container{
  background-color: var(--nta-green);
  color: white;
  padding: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  z-index: 11000;

}
.sending-email-spinner{
  position: relative;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50px;
  animation: spinner 1.5s linear infinite;
}



/* Error Page */
.error-page{
  margin-top: 30vh;
}




/* Home */
.home-image{
  width: 100%;
  margin-left: 0%;
  margin-top: 0%;
  height: 90vh;
}
.home-container{
  background-color: var(--nta-green);
  border-radius: 30px;
  border-style: none;
  width: 80%;
  margin-left: 10%;
  color: white;
  padding: 1rem;
}
.nta-title{
  position: "relative";
  top: "12.5vh";
  text-align: "center";
}
.home-title{
  text-align: center;
  position: relative;
  margin-top: 2%;
}
.home-content{
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
}
.features{
  width: 70%;
  margin-left: 10%;
  background-color: lightgray;
  border-radius: 30px;
  padding-bottom: 1rem;
  padding-top: .1rem;
}
.features > h2{
  margin-left: 10%;
}
.features > p{
  padding-left: 10%;
  width: 70%;
}



/* Home Slider */
.slider-container{
  position: relative;
  width: 100%;
  overflow: hidden;
  height: auto;
  margin-top: 15vh;
}
.slider{
  position: relative;
  width: 70%;
  height: 55vh;
  /* height: auto; */
  margin-left: 15%;
  margin-top: 0%;
  margin-bottom: 5%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-image-initial{
  border-radius: 30px;
  width: 100%;
  height: auto;
  max-height: 55vh;
  position: relative;
  z-index: 0;
}
.slider-arrow{
  position: absolute;
  width: 50px;
  height: 50px;
  border-top: 0px;
  border-right: 0px;
  border-left: 5px;
  border-bottom: 5px;
  border-color: var(--nta-green);
  border-style: solid;
  z-index: 1;
  top: 42%;
  border-radius: 5px;
}
.slider-arrow:hover{
  cursor: pointer;
  transition-duration: .5s;
  opacity: .8;
}
.slider-left-arrow{
  transform: rotate(45deg);
  left: -10%;
}
.slider-right-arrow{
  transform: rotate(225deg);
  right: -10%;
}
.image-slide-left{
  animation-name: image-slide-left;
  animation-duration: var(--image-slide-time);
}
.image-slide-right{
  animation-name: image-slide-right;
  animation-duration: var(--image-slide-time);
}
.image-slide-left-second{
  animation-name: image-slide-left-second;
  animation-duration: var(--image-slide-time);

}
.image-slide-right-second{
  animation-name: image-slide-right-second;
  animation-duration: var(--image-slide-time);
}
/* Slide off Animations */
@keyframes image-slide-left {
  0%{
    opacity: 1;
  }
  100%{
    opacity: .1;
    transform: translateX(120%);
  }
}
@keyframes image-slide-right {
  0%{
    opacity: 1;
  }
  100%{
    opacity: .1;
    transform: translateX(-120%);
  }
}

/* Slide on Animations */
@keyframes image-slide-left-second {
  0%{
    transform: translateX(-120%);
    opacity: .1;
  }
  100%{ 
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes image-slide-right-second {
  0%{
    transform: translateX(120%);
    opacity: .1;
  }
  100%{
    opacity: 1;
    transform: translateX(0%);
  }
}





/* Footer */
footer{
  overflow: hidden;
  position: relative;
  background-color: black;
  height: 50vh;
  display: flex;
  /* align-items: center;
  justify-content: center;
  text-align: center; */
  position: relative;
  width: 100%;
  z-index: 0;
  padding-bottom: 10%;
  justify-content: center;
}
.footer-segment{
  margin-top: 5vh;
  color: white;
  height: 30vh;
  width: auto;
  padding: 1rem;
  text-align: left;
}
.footer-icon{
  width: auto;
  height: 30vh;
  padding: 1rem;
}
.facebook-icon{
  width: 10%;
  height: auto;
  margin-left: -1.8%;
}
.facebook-icon:hover{
  cursor: pointer;
}
.loc::before{
  content: '\f041';
  position: relative;
  left: 0;
  font-size: 16px;
  font-family: FontAwesome;
  padding-right: 10px;
}
.contact-us-footer-grid{
  font-size: 14px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: auto;
  margin-left: 10%;
}
.contact-us-footer-grid > div{
  margin-top: .3rem;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.5rem;
}
.contactus-footer{
  text-align: left;
}
.follow-us{
  width: 10rem;
}
.quick-links{
  color: white;
  height: 100%;
  left: 33%;
}
.quick-links > div{
  color: var(--quick-links-text-color);
  margin-top: .5rem;
  text-align: left;
  opacity: 1;
}
.quick-links > div:hover{
  cursor: pointer;
  opacity: 1;
  transition-duration: .5s;
  /* color: #115633; */
  /* color: green; */
}
.contactus-map{
  margin-top: 30px;
}





.failed-email-card-container{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
}
.failed-email-card{
  background-color: var(--nta-green);
  width: 40%;
  height: auto;
  border-radius: 30px;
  padding: 2rem;
  color: white;
}
.failed-email-card > button{
  float: right;
  padding: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}