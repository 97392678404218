/* .overview{
    width: 65%;
    background-image: linear-gradient(#145434, #185434);
    color: white;
    margin-left: 35%;
    margin-top: 10%;
    margin-bottom: 7.5%;
    border-radius: 30px 0 0 30px;
}
.overview> h2{
    padding-left: 2rem;
    padding-top: 2rem;
}
.overview > p{
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
} */
.overview{
    width: 55%;
    margin-left: 22.5%;
    background-color: var(--nta-green);
    border-radius: 20px;
    padding: 1rem;
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 5%;
    color: white;
}



.afterschool-content{
    /* background-color: #A9A9A9; */
    /* background-color: #7c7c7c; */
    /* background-image: linear-gradient(#145434, #185434); */
    background-image: linear-gradient(var(--nta-green), var(--scheme-green));

    color: white;
    width: 80%;
    margin-left: 10%;
    border-radius: 20px;
    margin-bottom: 5%;
}
.afterschool-content > h2{
    padding-left: 2rem;
    padding-top: 2rem;
    font-size: 2.5rem;
}
.image-segment{
    display: flex;
    padding-bottom: 2rem;
    padding-left: 5%;
    margin-top: 2rem;
}
.image-segment-text{
    padding-right: 7%;
    font-size: .8rem;
}
.right-image{
    margin-right: 2rem;
    border-radius: 20px 20px 20px 20px;
    width: 40%;
    height: 80%;
}
.afterschool-underline{
    border: 2px solid whitesmoke;
    border-radius: 2px;
    margin-left: 2%;
    width: 60%;
}

@media screen and (min-width: 1439px) {
    .image-segment-text > p{
        font-size: 1.2rem;
    }
}
@media screen and (min-width: 2000px) {
    .image-segment-text > p, .overview > p{
        font-size: 2rem;
    }
    .image-segment-text > h3, .overview > h2{
        font-size: 4rem;
    }
    .afterschool-content > h2{
        font-size: 6rem;
    }
}


/* Second Afterschool go */
.after-school-card{
    width: 60%;
    margin-left: 20%;
    height: 30vh;
    background-color: var(--nta-green);
    border-radius: 20px;
    color: white;
    position: relative;
    padding: 3rem;
    padding-right: 0;
    padding-bottom: 0;
    display: flex;
    margin-top: 3%;
}
.left-card{
    padding-bottom: 1rem;
    padding-right: 4rem;
}
.temp-right-image{
    border-radius: 20px;
    max-width: 35%;
}

.after-school-card:nth-child(odd){
    /* text-align: end; */
    padding-left: 0;
}
.after-school-card:nth-child(odd) .left-card{
    padding-left: 4rem;
}