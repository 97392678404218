/* Mobile */
@media screen and (max-width: 550px) {
  .slider-container{
    margin-top: 0;
  }
  /* Navbar */
  .mynav{
    z-index: 5;
  }
  .mobile-nav{
    display: flex;
    flex-direction: column;
    padding-top: 10%;
  }
  .nav-tab{
    font-size: 1rem;
    margin-left: 0rem;
    margin-top: 10%;
    opacity: 1;
    color: white;

    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .desktop-tab{
    margin-left: 1.5rem;
    opacity: 1;
    /* color: var(--quick-links-text-color); */
    /* color: rgb(9, 97, 9); */
    color: rgb(9, 87, 9);
  
    font-size: 1.2rem;
  }
  .icon{
    margin-top: 0;
    margin-left: 1rem;
  }
  .hidden{
    display: none;
  }

  
  
  /* Programs */
  .programs{
    flex-direction: column;
  }
  .program-image{
    width: 80%;
    height: 40vh;
    margin-left: 10%;
  }
  .traditional-program{
    margin-top: 20%;
  }
  .programs-header{
    margin-top: 25%;
  }
  .hammadang-program{
    margin-bottom: 5%;
  }

    


  /* AfterSchool */
  .image-segment{
    height: auto;
    flex-direction: column-reverse;
  }
  .right-image{
    width: 90%;
    margin-right: 0%;
    margin-left: 2.5%;
  }
  .image-segment:nth-child(odd){
    flex-direction: column-reverse;
  }
  .image-segment:nth-child(even){
    flex-direction: column;
  }
  .overview{
    margin-top: 30%;
    margin-left: 5%;
    width: 90%;
  }
  .afterschool-content{
    width: 90%;
    margin-left: 5%;
    margin-bottom: 8%;
  }
  /* .after-school-card{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 2rem;
  }
  .temp-right-image{
    max-width: 100%;
    width: 80%;
  }
  .left-card{
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .after-school-card:nth-child(odd){
    padding-left: 0;
  }
  .after-school-card:nth-child(odd) .left-card{
    padding-left: 2rem;
  } */

  
  /* Reviews */
  .reviews-container{
    grid-template-columns: 100%;
    margin-left: 0%;
    margin-right: 0%;
    grid-gap: 2%;
    margin-top: 8vh;
    padding-bottom: 35%;
  }
  .reviews-title{
    position: relative;
    padding-top: 2rem;
  }
  .my-2{
    width: 90%;
  }
  .card-title{
    font-size: 1.4rem;
  }
  .card-text{
    font-size: 1.1rem;
  }
  
  
  
  
  /* Contact */
  .contact-us{
    flex-direction: column;
  }
  .contactus-card{
    width: 90%;
    margin-left: 5%;
    margin-top: 25%;
    padding-left: 2rem;
    padding-right: 2rem;  
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
  .contact-input{
    width: 90%;
    padding-bottom: 1rem;
    border-radius: 10px;
    margin-left: 5%;
    margin-top: 5%;
  }
  .contact-form{
    width: 90%;
    margin-left: 5%;
  }
  .contact-us-form > label{
    font-size: 1rem;
  }
  .card-text{
    font-size: .8rem;
  }


  /* Schedule */
  .mobile-schedule{
    margin-top: 20%;
    width: 90%;
    margin-left: 5%;
  }
  .mobile-schedule-day-container:nth-last-child(1){
    margin-bottom: 20%;
  }
  .mobile-schedule-day-container{
    text-align: left;
    padding-bottom: 2rem;
    background-color: var(--scheme-green);
    color: white;
    margin-top: 10%;

    border-radius: 10px;
  }
  .mobile-schedule-day-container > h4{
    text-align: left;
    padding-left: 5%;
    padding-top: 5%;
    padding-bottom: 2%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px;
    border-color: white;
    border-style: solid;
    margin-bottom: 0;
  }
  .mobile-schedule-day-container:nth-child(odd){
    background-color: var(--nta-green);
  }
  .mobile-times-grid{
    margin-top: 2%;
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .mobile-times-grid > p{
    padding-left: 15%;
  }



  /* Home */
  .home-content{
    padding-left: 5%;
    padding-right: 5%;
  }
  .slider{
    width: 100%;
    margin: 0;
    margin-top: 25%;
    padding-bottom: 5%;
  }
  .slider-left-arrow{
    transform: rotate(45deg);
    left: 3%;
  }
  .slider-right-arrow{
    transform: rotate(225deg);
    right: 3%;
  }
  .home-image{
    height: 40vh;
    width: 100%;
    margin-left: 0%;
    margin-top: 0%;
  }
/* Slide off Animations */
@keyframes image-slide-left {
  0%{
    opacity: 1;
  }
  100%{
    opacity: .1;
    transform: translateX(80%);
  }
}
@keyframes image-slide-right {
  0%{
    opacity: 1;
  }
  100%{
    opacity: .1;
    transform: translateX(-80%);
  }
}

/* Slide on Animations */
@keyframes image-slide-left-second {
  0%{
    transform: translateX(-110%);
    opacity: .1;
  }
  100%{
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes image-slide-right-second {
  0%{
    transform: translateX(110%);
    opacity: .1;
  }
  100%{
    opacity: 1;
    transform: translateX(0%);
  }
}


    /* Footer */
    footer{
      overflow: hidden;
      position: relative;
      background-color: black;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      width: 100%;
      margin-top: 0rem;
      padding-bottom: 30%;

      z-index: 0;
    }
    .footer-icon, .contact-us-footer-grid{
      position: relative;
    }
    .footer-icon{
      height: 30%;
      margin-top: 0%;
      padding-top: 5vh;
      margin-left: 3%;
    }
    .quick-links{
      position: relative;
      /* left: -20%; */
      height: auto;
    }
    .contact-us-footer-grid{
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 0;
    }
    .footer-segment{
      margin-top: 8%;
    }
  }
  










/* Mobile Turned Sideways */
@media screen and (orientation: landscape) and (max-height: 450px) {
  /* Navbar */
  .hamburger-nav{
    top: 1vh;
  }
  .hamburger-line{
    border-width: .4ex;
    margin-top: 7%;
    width: 5vw;
  }
  .mobile-nav{
    height: 100vh !important;
  }
  .nav-tab{
    margin-top: 5%;
  }
  .programs-tab{
    margin-top: 2%;
  }
  .icon{
    margin-top: 0;
  }


  /* Contact Us */
  .contact-form{
    width: 80%;
    margin-right: 4%;
  }
  .contactus-card{
    margin-left: 4%;
  }
  .card-text{
    font-size: .8rem;
  }



  /* Schedule */
  .mobile-schedule-day-container{
    margin-top: 5%;
    background-color: var(--scheme-green);
  }
  .schedule-title{
    margin-top: 7%;
  }
  .mobile-schedule-day-container:nth-child(odd){
    background-color: var(--nta-green);
  }
  .mobile-times-grid{
    margin-top: 2%;
    display: grid;
    grid-template-columns: 40% 60%;
  }







  /* Home */
  .home-image{
    margin-top: -4.5%;
  }
}

@media screen and (orientation: landscape) and (max-width: 950px) {
  @keyframes bottom-nav-slide {
    0%{
      height: 10vh;
    }
    100%{
      height: 80vh;
    }
  }
  @keyframes bottom-nav-slide-down {
    0%{
      height: 80vh;
    }
    100%{
      height: 10vh;
    }
  }
  @keyframes bottom-form-slide {
    0%{
      top: 130%;
    }
    100%{
      top: 58%
    }
  }
  @keyframes bottom-form-slide-down {
    0%{
      top: 58%
    }
    100%{
      top: 130%;
    }
  }
  :root{
    --bottom-slide-percentage: -10vh;
  }
  .card-group{
    flex-flow: column nowrap;
  }
  .bottom-nav-form{
    padding-top: 0;
    top: 58%;
    margin-bottom: 0%;
    animation: bottom-form-slide 1s;
    height: 10%;
  }
  .bottom-nav-form > input{
    padding: 0;
  }
  .closed-form{
    animation: bottom-form-slide-down 1s;
    animation-fill-mode: forwards;
  }
  .bottom-text-container{
    z-index: 150;
  }
  .slide-down-animation{
    height: 10vh;
  }
  .open-bottom-nav{
    height: 80vh;
  }
  .bottom-nav-form > label{
    padding-left: 0%;
  }
  .bottom-form-submit-button{
    margin-top: 2%;
  }

  

}